var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "bridge" }, [
    _c("div", { staticClass: "bridge-top" }, [_vm._t("top")], 2),
    _c("div", { staticClass: "bridge-list" }, [
      _c("div", { staticClass: "bridge-list__top-gradient" }),
      _c(
        "div",
        { ref: "scrollContainer", staticClass: "bridge-list__inner" },
        [_vm._t("list")],
        2
      ),
      _c("div", { staticClass: "bridge-list__bottom-gradient" }),
    ]),
    _c("div", { staticClass: "bridge-bottom" }, [_vm._t("bottom")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }