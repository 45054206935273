var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { staticClass: "mb-n2", attrs: { dense: "" } },
    [
      _c(
        "v-col",
        { staticClass: "d-flex justify-center", attrs: { cols: "auto" } },
        [
          _c("v-checkbox", {
            staticClass: "my-auto",
            attrs: { value: _vm.tripRequest.id },
            on: { change: _vm.handleSelected },
            model: {
              value: _vm.selected,
              callback: function ($$v) {
                _vm.selected = $$v
              },
              expression: "selected",
            },
          }),
        ],
        1
      ),
      _c(
        "v-col",
        { staticClass: "flex-grow-1", attrs: { cols: "auto" } },
        [
          _c("trip-request", {
            attrs: {
              tripRequest: _vm.tripRequest,
              destinations: _vm.destinations,
              specialIndicators: _vm.specialIndicators,
              config: _vm.tripRequestConfig,
              allExpanded: _vm.allExpanded,
              hideQuickActions: true,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }