var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "90%", "no-click-animation": "" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("Mass Assign to Recurring Trips")]),
          _c(
            "v-card-text",
            { staticClass: "list-wrapper" },
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "4" } },
                    [
                      _c("v-select", {
                        attrs: {
                          label: "Batch ID",
                          items: _vm.batchIds,
                          "item-text": "label",
                          "item-value": "value",
                          outlined: "",
                        },
                        on: { change: _vm.handleBatchIdSelected },
                        model: {
                          value: _vm.batchId,
                          callback: function ($$v) {
                            _vm.batchId = $$v
                          },
                          expression: "batchId",
                        },
                      }),
                      _c(
                        "v-radio-group",
                        {
                          staticClass: "mt-n4",
                          attrs: { row: "" },
                          on: { change: _vm.applyFilter },
                          model: {
                            value: _vm.filter,
                            callback: function ($$v) {
                              _vm.filter = $$v
                            },
                            expression: "filter",
                          },
                        },
                        [
                          _c("v-radio", {
                            attrs: { label: "All Trips", value: "all" },
                          }),
                          _c("v-radio", {
                            attrs: {
                              label: "Need Vehicle(s)/Driver(s)",
                              value: "pending",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "4" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Change # of Vehicles Needed",
                          type: "number",
                          min: "0",
                          hint: _vm.currentNumVehicles,
                        },
                        model: {
                          value: _vm.numVehicles,
                          callback: function ($$v) {
                            _vm.numVehicles = _vm._n($$v)
                          },
                          expression: "numVehicles",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "4" } },
                    [
                      _c("v-select", {
                        attrs: {
                          label: "Change Destination",
                          items: _vm.destinations,
                          "item-text": "name",
                          "item-value": "id",
                          outlined: "",
                        },
                        model: {
                          value: _vm.destinationId,
                          callback: function ($$v) {
                            _vm.destinationId = $$v
                          },
                          expression: "destinationId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._l(_vm.numberOfVehicles, function (index, rowIndex) {
                return _c(
                  "v-row",
                  { key: index, attrs: { dense: "" } },
                  [
                    _c("v-spacer"),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", md: "4" } },
                      [
                        _c("v-select", {
                          attrs: {
                            label: `Driver ${index}`,
                            items: _vm.filteredDrivers(rowIndex),
                            "item-text": _vm.getDriverText,
                            "item-value": "id",
                            outlined: "",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.handleDriverSelected(rowIndex)
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "append-outer",
                                fn: function () {
                                  return [
                                    _vm.driverId[rowIndex]
                                      ? _c(
                                          "v-icon",
                                          {
                                            attrs: { color: "error" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.clearSelection(
                                                  "driver",
                                                  rowIndex
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("mdi-close-circle")]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                          model: {
                            value: _vm.driverId[rowIndex],
                            callback: function ($$v) {
                              _vm.$set(_vm.driverId, rowIndex, $$v)
                            },
                            expression: "driverId[rowIndex]",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", md: "4" } },
                      [
                        _c("v-select", {
                          attrs: {
                            label: `Vehicle ${index}`,
                            items: _vm.filteredVehicles(rowIndex),
                            "item-text": "name",
                            "item-value": "id",
                            outlined: "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "append-outer",
                                fn: function () {
                                  return [
                                    _vm.vehicleId[rowIndex]
                                      ? _c(
                                          "v-icon",
                                          {
                                            attrs: { color: "error" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.clearSelection(
                                                  "vehicle",
                                                  rowIndex
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("mdi-close-circle")]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                          model: {
                            value: _vm.vehicleId[rowIndex],
                            callback: function ($$v) {
                              _vm.$set(_vm.vehicleId, rowIndex, $$v)
                            },
                            expression: "vehicleId[rowIndex]",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              _vm.filteredTripRequests.length > 0
                ? [
                    _c(
                      "v-row",
                      { staticClass: "mt-n8", attrs: { dense: "" } },
                      [
                        _c("v-spacer"),
                        _c(
                          "v-col",
                          { attrs: { cols: "12", md: "3" } },
                          [
                            _c("v-switch", {
                              staticClass: "mx-2 right",
                              attrs: { label: "Expand All" },
                              model: {
                                value: _vm.allExpanded,
                                callback: function ($$v) {
                                  _vm.allExpanded = $$v
                                },
                                expression: "allExpanded",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.loading
                      ? _c(
                          "v-row",
                          { attrs: { dense: "" } },
                          [
                            _c("v-progress-circular", {
                              staticClass: "progress",
                              attrs: {
                                size: 50,
                                color: "primary",
                                indeterminate: "",
                              },
                            }),
                          ],
                          1
                        )
                      : _c(
                          "v-row",
                          _vm._l(_vm.filteredTripRequests, function (tr) {
                            return _c(
                              "v-col",
                              {
                                key: tr.id + 9999999,
                                attrs: { cols: "12", dense: "" },
                              },
                              [
                                _c("selectable-trip-request", {
                                  attrs: {
                                    value: _vm.selected,
                                    tripRequest: tr,
                                    allExpanded: _vm.allExpanded,
                                  },
                                  on: { input: _vm.handleSelected },
                                }),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: { click: _vm.close },
                },
                [_vm._v(" Close ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    loading: _vm.saving,
                    disabled: _vm.buttonDisabled,
                  },
                  on: { click: _vm.assign },
                },
                [_vm._v(" " + _vm._s(_vm.buttonText) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }